import { capitalize } from 'lodash'
import moment from 'moment'
import ReactGA from 'react-ga4'
import { toast } from 'react-toastify'

import {
  ADMIN,
  CSV_MIME_TYPE,
  ELITE_ALLIANCE,
  ELITE_ALLIANCE_MUSIC,
  EPIC_ELITE,
  GA_EVENT_ACTIONS,
  GA_EVENT_CATEGORY,
  GA_ID,
  LANGUAGES,
  SERVER_DATE_FORMAT,
  SITE_SLUG,
  SONGMATE,
  SONGPITCH,
  YOUTUBE_CHANNEL_LINK_PREFIX,
  YOUTUBE_VIDEO_ID_EXTRACTOR_REGEX,
  YOUTUBE_VIDEO_LINK_PREFIX,
} from '../constants'

/** turns first character toUpperCase(), with rest unchanged */
export function capitalizeFirstLetter(str: string) {
  const text = str.toLowerCase()
  return text.charAt(0).toUpperCase() + text.slice(1)
}

/** turns first character toUpperCase(), with rest unchanged */
export function humanizeSnakeCase(str: string) {
  return str
    .split('_')
    .map(word => capitalize(word))
    .join(' ')
}

export function humanizeCamelCase(str: string) {
  const newStr = str.replace(/([A-Z])/g, ' $1')
  return newStr.charAt(0).toUpperCase() + newStr.slice(1)
}

/**
 * Changes the body attribute
 */
export function changeBodyAttribute(attribute: string, value: string): boolean {
  if (document.body) document.body.setAttribute(attribute, value)
  return true
}

/**
 * Changes a body class
 */
export function manageBodyClass(cssClass: string, action: 'toggle' | 'add' | 'remove' = 'toggle'): boolean {
  switch (action) {
    case 'add':
      if (document.body) document.body.classList.add(cssClass)
      break
    case 'remove':
      if (document.body) document.body.classList.remove(cssClass)
      break
    default:
      if (document.body) document.body.classList.toggle(cssClass)
      break
  }

  return true
}

/**
 * Get token saved in local storage
 */
export function getLocalToken(): null | string {
  const authJson = localStorage.getItem('auth')
  const { token } = JSON.parse(authJson || '{}')
  return token
}

/**
 * Get session time saved in local storage
 */
export function getExpiryDate(): null | number {
  const authJson = localStorage.getItem('auth')
  const { expiryDate } = JSON.parse(authJson || '{}')
  return expiryDate
}

/**
 * Copy text to clipboard
 */
export function handleCopyToClipboard(text: string): void {
  navigator.clipboard.writeText(text)
  toast.info(`Copied '${text}' to clipboard!`)
}

/**
 * Format the numbers for better readability
 * this is taken and adapted from php number format
 * https://locutus.io/php/strings/number_format/
 */
export function numberFormat(number: number, decimals = 0, dec_point?: string, thousands_sep?: string): string {
  // *     example 1: number_format(1234.56);
  // *     returns 1: '1,235'
  // *     example 2: number_format(1234.56, 2, ',', ' ');
  // *     returns 2: '1 234,56'
  // *     example 3: number_format(1234.5678, 2, '.', '');
  // *     returns 3: '1234.57'
  // *     example 4: number_format(67, 2, ',', '.');
  // *     returns 4: '67,00'
  // *     example 5: number_format(1000);
  // *     returns 5: '1,000'
  // *     example 6: number_format(67.311, 2);
  // *     returns 6: '67.31'
  // *     example 7: number_format(1000.55, 1);
  // *     returns 7: '1,000.6'
  // *     example 8: number_format(67000, 5, ',', '.');
  // *     returns 8: '67.000,00000'
  // *     example 9: number_format(0.9, 0);
  // *     returns 9: '1'
  // *    example 10: number_format('1.20', 2);
  // *    returns 10: '1.20'
  // *    example 11: number_format('1.20', 4);
  // *    returns 11: '1.2000'
  // *    example 12: number_format('1.2000', 3);
  // *    returns 12: '1.200'
  const n = !Number.isFinite(+number) ? 0 : +number
  const prec = !Number.isFinite(+decimals) ? 0 : Math.abs(decimals)
  const sep = typeof thousands_sep === 'undefined' ? ',' : thousands_sep
  const dec = typeof dec_point === 'undefined' ? '.' : dec_point
  function toFixedFix(nr: number, precc: number) {
    const k = 10 ** precc
    return Math.round(nr * k) / k
  }
  const s = (prec ? toFixedFix(n, prec) : Math.round(n)).toString().split('.')
  if (s[0].length > 3) {
    s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep)
  }
  if ((s[1] || '').length < prec) {
    s[1] = s[1] || ''
    s[1] += new Array(prec - s[1].length + 1).join('0')
  }
  return s.join(dec)
}

/**
 * Format money values
 */
export function moneyFormat(number: number, decimals = 2): string {
  return `$${numberFormat(number, decimals)}`
}

/**
 *
 */
export function getPercentage(value: number, total: number) {
  if (!total) return '--'
  return `${((value * 100) / total).toFixed(2)}%`
}

/** calculate % change from on value to another */
export const calculatePercentageDifference = (currentValue: number | null, from: number | null): number => {
  if (currentValue === null || from === null || from === 0) return NaN

  return Math.round(((currentValue - from) / from) * 10000) / 100
}

/**
 * Format milliseconds to readable time
 */
export function msToTime(s: string | number, withMilliseconds = false) {
  let time = typeof s === 'string' ? Math.floor(Number(s)) : Math.floor(s)

  const ms = time % 1000
  time = (time - ms) / 1000
  const secs = time % 60
  time = (time - secs) / 60
  const mins = time % 60
  const hrs = (time - mins) / 60

  const formattedTime = `${mins < 10 ? `0${mins}` : mins}:${secs < 10 ? `0${secs}` : secs}${
    withMilliseconds ? `.${ms}` : ''
  }`

  return hrs > 0 ? `${hrs < 10 ? `0${hrs}` : hrs}` : formattedTime
}

/**
 * Date month  year format ex: May 2020
 */
export function dateFormatMonthYear(date: string): string {
  return moment(date).format('MMM YYYY')
}

/**
 * Date format ex: 17/10/2021
 */
export function dateFormatUS(date: string | null): string {
  if (date !== null) {
    return moment(date).format('DD/MM/YYYY')
  }
  return 'Unknown'
}

/**
 * Date format ex Aug 30, 2022
 */
export function defaultDateFormat(date: string | null): string {
  if (date !== null) {
    return moment(date, SERVER_DATE_FORMAT).format('ll')
  }
  return 'Unknown'
}

/**
 * Helper function for downloadCSV function
 */
function convertDataToCSV(data: Array<Record<string, unknown>>): string | null {
  try {
    let result: string

    if (!data || data.length === 0) return null
    if (!data[0]) return null

    const header = Object.keys(data[0])
      .map(key => {
        const uppCase = key.replace(/([A-Z])/g, ' $1')
        return capitalizeFirstLetter(uppCase)
      })
      .join(',')

    result = `${header}\n`

    data.forEach(datum => {
      const row = `${Object.values(datum)
        .map((value: unknown) => {
          if (!value) return '-'

          return typeof value === 'object' && value ? Object.values(value).join('-') : value
        })
        .join(',')}\n`

      result += row
    })
    return result
  } catch (e) {
    console.error(e)
    return null
  }
}

/**
 * Receives a data string and saves a PDF file corresponding to it
 */
export function downloadPDF(data: string, filename = 'export'): void {
  const link = document.createElement('a')

  const blob = new Blob([data], { type: 'application/pdf' })
  const pdfUrl = URL.createObjectURL(blob)

  link.setAttribute('href', pdfUrl)
  link.setAttribute('download', `${filename}.pdf`)
  link.click()
  if (GA_ID) {
    ReactGA.event({
      category: GA_EVENT_CATEGORY.DOWNLOAD,
      action: GA_EVENT_ACTIONS.DOWNLOAD_PDF,
      label: filename,
    })
  }
}

/**
 * Receives a data string and saves a XLSX file corresponding to it
 */
export function downloadXLSX(data: string, filename = 'export'): void {
  const link = document.createElement('a')

  const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
  const xlsxUrl = URL.createObjectURL(blob)

  link.setAttribute('href', xlsxUrl)
  link.setAttribute('download', `${filename}.xlsx`)
  link.click()

  if (GA_ID) {
    ReactGA.event({
      category: GA_EVENT_CATEGORY.DOWNLOAD,
      action: GA_EVENT_ACTIONS.DOWNLOAD_EXCEL,
      label: filename,
    })
  }
}

/**
 * Receives an array of data and saves a CSV file corresponding to it
 */
export function downloadCSV(data: Array<Record<string, unknown>> | string, filename = 'export'): void {
  const link = document.createElement('a')
  const csv = typeof data === 'string' ? data : convertDataToCSV(data)

  if (csv === null) return

  const csvData = new Blob([csv], { type: CSV_MIME_TYPE })
  const csvUrl = URL.createObjectURL(csvData)

  link.setAttribute('href', csvUrl)
  link.setAttribute('download', `${filename}.csv`)
  link.click()

  if (GA_ID) {
    ReactGA.event({
      category: GA_EVENT_CATEGORY.DOWNLOAD,
      action: GA_EVENT_ACTIONS.DOWNLOAD_CSV,
      label: filename,
    })
  }
}

/**
 * Receives an array of data and saves a ZIP file corresponding to it
 */
export function downloadZip(data: string, filename = 'distributionFiles'): void {
  const link = document.createElement('a')

  const blob = new Blob([data], { type: 'application/zip' })
  const zipUrl = URL.createObjectURL(blob)

  link.setAttribute('href', zipUrl)
  link.setAttribute('download', `${filename}.zip`)
  link.click()

  if (GA_ID) {
    ReactGA.event({
      category: GA_EVENT_CATEGORY.DOWNLOAD,
      action: GA_EVENT_ACTIONS.DOWNLOAD_CSV,
      label: filename,
    })
  }
}

/** Extract the video ID from a youtube URL */
export const youtubeUrlParser = (url: string): string | false => {
  const match = url.match(YOUTUBE_VIDEO_ID_EXTRACTOR_REGEX)
  return match && match[7].length === 11 ? match[7] : false
}

/** Extract the subdomain if there is any */
export const extractSubdomain = (): string | null => {
  const locationArray = window.location.hostname.split('.')
  if (locationArray.length === 3) {
    if (locationArray[0] !== 'www') return locationArray[0]
  }
  return null
}

/** get platform slug from fe platform */
export const getPlatformSlug = (platform: string): string | null => {
  switch (platform) {
    case ADMIN:
      return SITE_SLUG.ADMIN
    case SONGPITCH:
      return SITE_SLUG.SONGPITCH
    case SONGMATE:
      return SITE_SLUG.SONGMATE
    case EPIC_ELITE:
      return SITE_SLUG.EPIC_ELITE
    case ELITE_ALLIANCE:
      return SITE_SLUG.ELITE_ALLIANCE
    case ELITE_ALLIANCE_MUSIC:
      return SITE_SLUG.ELITE_ALLIANCE_MUSIC
    default:
      return null
  }
}

/** replace with * all string except first 2 chars */
export const starReplace = (text: string): string => {
  return `${text.slice(0, 2)}****`
}

/** returns name of language */
export const formatLanguages = (code: string | null, defaultValue = '') => {
  if (code) {
    return LANGUAGES.find(lan => lan.code === code)?.name
  }
  return defaultValue
}

export const swapElements = (arr: unknown[], i1: number, i2: number) => {
  // we want to reassign the param
  // eslint-disable-next-line no-param-reassign
  arr[i1] = arr.splice(i2, 1, arr[i1])[0]
}

/** resizes a image from dataUri */
export const resizeImage = (base64Str: string, maxWidth = 400, maxHeight = 350): Promise<string> => {
  return new Promise(resolve => {
    const img = new Image()
    img.src = base64Str
    img.onload = () => {
      const canvas = document.createElement('canvas')
      const MAX_WIDTH = maxWidth
      const MAX_HEIGHT = maxHeight
      let width = img.width
      let height = img.height

      if (width > height) {
        if (width >= MAX_WIDTH) {
          height *= MAX_WIDTH / width
          width = MAX_WIDTH
        }
      } else if (height > MAX_HEIGHT) {
        width *= MAX_HEIGHT / height
        height = MAX_HEIGHT
      }
      canvas.width = width
      canvas.height = height
      const ctx = canvas.getContext('2d')
      ctx?.drawImage(img, 0, 0, width, height)
      resolve(canvas.toDataURL())
    }
  })
}

export const openInNewTab = (url: string) => {
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('target', '_blank')

  document.body.appendChild(link)

  link.click()

  link?.parentNode?.removeChild(link)
}

export const checkImageExists = async (path: string): Promise<boolean> => {
  try {
    return await new Promise(resolve => {
      const img = new Image()
      img.onload = () => resolve(true)
      img.onerror = () => resolve(false)
      img.src = path
    })
  } catch (err) {
    console.error(err)
    return false
  }
}

export const getYoutubeVideoURL = (videoId: string | null) => {
  return `${YOUTUBE_VIDEO_LINK_PREFIX}${videoId}`
}

export const getYoutubeChannelURL = (channelId: string | null | undefined) => {
  return `${YOUTUBE_CHANNEL_LINK_PREFIX}${channelId}`
}

export const getMicrolicensingTrackURL = (productId: string | null | undefined) => {
  return `${process.env.REACT_APP_MICROLICENSING_URL}/song/${productId}`
}

export const getMicrolicensingPlaylistURL = (productId: string | null | undefined) => {
  return `${process.env.REACT_APP_MICROLICENSING_URL}/playlist/${productId}`
}
