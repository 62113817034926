import { SERVICE_NAME } from '../../../constants'
import { IParticipantBasic, IParticipantFormData } from '../../../models/assets/ParticipantBasic'
import { IGenericResponse } from '../../api.types'
import clientWrapper from '../../clientWrapper'
import { participantFragment } from '../fragments'

export type IParticipantCreateUpdateResponse = IGenericResponse<'Participant_createOrUpdate', IParticipantBasic>

export async function participantCreateOrUpdate(
  props: IParticipantFormData
): Promise<IParticipantCreateUpdateResponse> {
  const variables = {
    patch: props,
  }

  const query = `
    mutation participantCreateOrUpdate ($patch: ParticipantInput!) {
       Participant_createOrUpdate (patch: $patch) {
        ${participantFragment} 
      }
    }
  `

  return clientWrapper(SERVICE_NAME.ASSETS, query, variables) as Promise<IParticipantCreateUpdateResponse>
}
