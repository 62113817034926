import { Instance, cast, flow, types } from 'mobx-state-tree'
import { toast } from 'react-toastify'

import { Pagination } from '../general/Pagination.model'
import { ChartMetricUser } from './ChartMetricUser.model'
import {
  getChartMetricUsers,
  IGetChartMetricUsersProps,
  IGetChartMetricUsersResponse,
} from '../../api/assets-api/chartMetric/getChartMetricUsers'
import {
  deleteChartMetricUsers,
  IDeleteChartMetricUsersProps,
  IDeleteChartMetricUsersResponse,
} from '../../api/assets-api/chartMetric/deleteChartMetricUsers'
import {
  createChartMetricUser,
  ICreateChartMetricUserProps,
  ICreateChartMetricUserResponse,
} from '../../api/assets-api/chartMetric/createChartMetricUser'

export const ChartMetricUserList = types
  .model({
    list: types.array(ChartMetricUser),
  })
  .volatile(() => ({
    loading: false,
    pagination: Pagination.create({ totalItems: 1 }),

    cmArtistIdsFilter: [] as string[],
    userUuidsFilter: [] as string[],
  }))
  .actions(self => ({
    setCmArtistIds(ids: string[]) {
      self.cmArtistIdsFilter = ids
    },
    setUserUuidsFilter(uuids: string[]) {
      self.userUuidsFilter = uuids
    },
  }))
  .actions(self => ({
    load: flow(function* () {
      try {
        self.loading = true
        const variables: IGetChartMetricUsersProps = {
          pagination: self.pagination.allQueryParams,
          filters: {
            ...(self.userUuidsFilter.length > 0 && { userUuids: self.userUuidsFilter }),
            ...(self.cmArtistIdsFilter.length > 0 && { cmArtistIds: self.cmArtistIdsFilter }),
          },
        }

        const resp: IGetChartMetricUsersResponse = yield getChartMetricUsers(variables)

        if (resp && resp.data.data) {
          self.list = cast(resp.data.data.chartMetricUsers.users)
          self.pagination.setTotalItems(resp.data.data.chartMetricUsers.total)
        }
      } catch (err) {
        console.error(err)
      } finally {
        self.loading = false
      }
    }),
  }))
  .actions(self => ({
    createChartMetricUser: flow(function* (props: ICreateChartMetricUserProps) {
      try {
        self.loading = true
        const resp: ICreateChartMetricUserResponse = yield createChartMetricUser(props)

        if (resp && resp.data.data) {
          const user = resp.data.data.createChartMetricUser
          self.list.unshift(user)

          toast.success('The Chart Metric user has been created successfully!')
        }
      } catch (err) {
        console.error(err)
      } finally {
        self.loading = false
      }
    }),
  }))
  .actions(self => ({
    deleteChartMetricUsers: flow(function* (props: IDeleteChartMetricUsersProps) {
      try {
        self.loading = true
        const response: IDeleteChartMetricUsersResponse = yield deleteChartMetricUsers(props)

        if (response?.data.data?.deleteChartMetricUsers) {
          const deletedIds = props.uuids

          const newList = self.list.filter(el => !deletedIds.includes(String(el.cmArtistId)))
          self.list = cast(newList)

          toast.success('The Chart Metric user has been successfully deleted!')
        }
      } catch (err) {
        console.error(err)
      } finally {
        self.loading = false
      }
    }),
  }))

export type IChartMetricUserList = Instance<typeof ChartMetricUserList>
