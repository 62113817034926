import { claimFragment } from './contentId/fragments'

export const imageBasicFragment = `
  uuid
  storagePath
  releaseUuid
  downloadUrl
  fileName
`

export const imagesFragment = `
  uuid
  storagePath
  releaseUuid
  downloadUrl
  type
  thumbnailDownloadUrl
`

export const participantFragment = `
  uuid
  name 
  externalLinksToStores {
    platformName
    platformUrl
    imageUrl
  }
`
export const participantWithRoleFragment = `
  ${participantFragment}
  role
`

export const affiliatesFragment = `
  uuid
  type
  entityUuid
  affiliateUserUuid
  percentage
  siteUuid 
  lastAffiliationMonth 
`

export const channelAffiliationsBreakdownFragment = `
  channelUuid
  grossEarning
  networkShare
  revenuePercentage
  estimatedAdRevenueBeforeTax
  youtubeRedPartnerRevenueBeforeTax
  adjustmentAdRevenueBeforeTax
  adjustmentRedRevenueBeforeTax
  transactionRevenueBeforeTax
  shortAdRevenueBeforeTax
  shortRedRevenueBeforeTax
  taxWithheldCalculated
  taxRate
`

export const revenueShareFragment = `
  uuid
  entityUuid
  siteUuid
  type
  percentage
  bonusPercentage
  futurePercentage
  transactionPercentage
  progressiveThreshold
  fromDate
`

export const revenueShareFullFragment = `
  ${revenueShareFragment}
  createdBy
  updatedBy
`

export const channelAffiliatesAndRevenueShareFragment = `
  affiliates {
    ${affiliatesFragment}
  }
  revenueShare {
    ${revenueShareFragment}
  }
`

export const revenueSplitFragment = `
    uuid
    entityUuid
    siteUuid
    targetUserUuid
    userUuid
    type
    percentage
    isAfterRevenueShare
    createdAt
`

export const channelMinimalFragment = `
channels {
  uuid
  name
  icon
  siteUuid
  userUuid
  channelId
  onSongpitch
  users {
    userUuid
  }
}
`

export const channelFragmentLines = `
  uuid
  name
  icon
  siteUuid
  isActive
  publishDate
  latestUploadDate
  totalView
  totalVideo
  totalSubscriber
  clientName
  channelId
  users  {
    userUuid
    isOwner
  }
`

export const channelBasicFragmentLines = `
  uuid
  name
  icon
  siteUuid
  userUuid
  channelId
  isActive
  publishDate
  latestUploadDate
  status
  totalView
  totalVideo
  totalSubscriber
  clientName
  genreUuids
  linkedAt
  unlinkedAt
  assignedAt
  updatedBy
  users 
  {
    userUuid
    revSplitUuid
    percentage
    isOwner
  }
 
`

export const channelBasicFragment = `
channels {
  ${channelBasicFragmentLines}
}
`

export const channelFullFragment = `
  channels{
    ${channelBasicFragmentLines}
    ${channelAffiliatesAndRevenueShareFragment}
  }
`

export const dailyOfficialAssetEarningsFragment = `
  earningDate
  estimatedAdRevenue
  estimatedRedPartnerRevenue
  adjustmentAdRevenue
  adjustmentRedRevenue
  isOfficial
  totalEarning
`

export const artistSearchFragment = `
  platformName
  name
  url
  imageUrl
`

export const storeFragment = `
  uuid
  name
  id
  storeKey
`

export const channelWhitelistFragment = `
  uuid
  channelId
  channelName
  userUuid
  siteUuids
  assetUuids
  status
  rejectedReason
  createdAt
`

export const motionArrayUrlFragment = `
  urls {
    url
    uuid
    videoId
    claimId
    userUuid
    createdAt
    claim {
      ${claimFragment}
    }
  }
`

export const cmsFragment = `
  uuid
  name
  siteUuid
  onBehalfOfContentOwner
`
export const genresFragment = `
  uuid
  name
  parentGenre {
    uuid
    name
  }
  subGenres {
    uuid
    name
  }
`

export const isrcEmbedsFragment = `
  uuid
  isrc
  createdBy
  createdAt
  updatedAt
  soundRecordingName
  artistName
  youTubeVideoId
`

export const isrcStatisticsFragment = `
  codeType
  remainingCodeCount
  usedCodeCount
`

export const isrcCodesWithTrackDetailsFragment = `
  duration
  explicit
  isrc
  isrcValidated
  pYear
  recordingArtist
  recordingTitle
  recordingTitleVersion
  upc
  upcValidated
  releaseUuid
`

export const songpitchStatisticsFragment = `
  totalViews
  totalEarnings
  totalAudios
  totalVideos
  firstEarningDate
`

export const songpitchEstimatedEarningsPerUserFragment = `
  videoId
  videoUuid
  videoTitle
  pitchUuid
  assetUuid
  assetTitle
  estimatedEarning
  earningDate
`

export const pitchAsLoggedInFragment = `
  uuid
  pitcherUuid
  pitcherEmail
  pitcherFirstName
  pitcherLastName
  pitcherOrganizationName
  eSignDocId
  eSignDocCompletedAt
  createdAt
  pitchUuids
`

export const pitcheeChannelsFragment = `
  name
  uuid
  slug
`

export const pitchFragment = `
  uuid
  toChannels
  {
    uuid
    name
  }
  genres
  createdAt
  status
  asset
  {
    downloadUrl
    title
    artist
    releaseDate
    isrc
  }
`

export const pitchVideosFragment = `
  channelUuid
  pitchUuid
  pitcherUuid
  status
  videos {
    videoId
    assetUuid
    channelVideo 
    {
      videoId
      title
    }
  }
  pitch {
    uuid
    assetUuid
    createdAt
  }
  asset {
    uuid
    title
    artist
    releaseDate
  }
  channel {
    uuid
    name
    userUuid
  }
`

export const pitchesAsUserFragment = `
  uuid
  createdAt
  assetUuid
  genres
  pitcherUuid
  videosAsUser
  status
  toChannels
  {
    uuid
    name
  }
  asset 
  {
    title
    uuid
    artist
    releaseDate
    downloadUrl
    genreUuids
  }
`

export const pitchesAsChannelFragment = `
  uuid
  createdAt
  assetUuid
  genres 
  videosAsChannel(channelUuid: $channelUuid)
  asset 
  {
    title
    uuid
    artist
    releaseDate
    downloadUrl
    genreUuids
  }
  channelPitchStatus(channelUuid: $channelUuid)
`

export const spotifyAlbumsFragment = `
  albumType
  id
  imageUrl
  name
  url
`

export const monthlyEarningReportFragment = `
  earningDate
  isOfficial
  totalRevenue
  adRevenue
  redPartnerRevenue
  contentIdRevenue
  youtubeMusicRevenue
  publishingAdRevenue
  publishingRedPartnerRevenue
  channelAdRevenue
  channelRedPartnerRevenue
  shortsRevenue
  transactionRevenue
  affiliateRevenue
`

export const estimatedRevenueFragment = `
  siteUuid
  earningDate
  estimatedRevenue
  estimatedDailyRevenue
`

export const dailyEstimatedEarningsFragment = `
  earningDate
  estimatedTotalRevenue
  adRevenue
  redPartnerRevenue
  contentIdRevenue
  youtubeMusicRevenue
  publishingAdRevenue
  publishingRedPartnerRevenue
  channelAdRevenue
  channelRedPartnerRevenue
  shortsRevenue
  transactionRevenue
  affiliateRevenue
  adjustment
`

export const channelNamesFragment = `
  uuid
  name
  channelId
`

export const channelEarningBasicFragment = `
  uuid
  userUuid
  siteUuid
  channelUuid
  earningDate
  estimatedAdRevenue
`

export const channelEarningFragment = `
  ${channelEarningBasicFragment}
  estimatedRedPartnerRevenue
`

export const channelEarningMonthlyForChannelFragment = `
  ${channelEarningBasicFragment}
  channelId 
  channelName   
  netEarning
  estimatedAdRevenueNet
  youtubeRedPartnerRevenue
  youtubeRedPartnerRevenueNet
  adjustmentAdRevenue
  adjustmentAdRevenueNet
  adjustmentRedRevenue 
  adjustmentRedRevenueNet
  transactionRevenue
  transactionRevenueNet
  shortsAdRevenue
  shortsRedRevenue
  shortsAdRevenueNet
  shortsRedRevenueNet
  nonTransactionRevPercentage 
  transactionRevPercentage 
  youtubeAdjustment
  youtubeAdjustmentNet
  taxWithheld
  affiliateUserUuids 
  affiliatePercentages 
  createdAt
`

export const channelEarningMonthlyForUserFragment = `
  ${channelEarningBasicFragment}
  channelId 
  channelName   
  estimatedAdRevenueNet
  estimatedAdRevenueTax
  estimatedAdRevenueBeforeTax
  youtubeRedPartnerRevenue
  youtubeRedPartnerRevenueNet
  youtubeRedPartnerRevenueTax
  youtubeRedPartnerRevenueBeforeTax
  adjustmentAdRevenue
  adjustmentAdRevenueNet
  adjustmentAdRevenueTax
  adjustmentAdRevenueBeforeTax
  adjustmentRedRevenue 
  adjustmentRedRevenueNet
  adjustmentRedRevenueTax
  adjustmentRedRevenueBeforeTax
  transactionRevenue 
  transactionRevenueNet
  transactionRevenueTax
  transactionRevenueBeforeTax
  nonTransactionRevPercentage 
  contentIdRevenue
  contentIdRevenueNet
  shortsAdRevenue
  shortsAdRevenueNet
  shortsRedRevenue
  shortsRedRevenueNet
  transactionRevPercentage 
  taxRate
  taxWithheld
  taxWithheldCalculated
  affiliateUserUuids 
  affiliatePercentages 
  createdAt 
  netCommission
  grossCommission
  monthlyAdjustment 
  affiliateDeduction
  affiliateEarning
  grossEarning
  netEarning
  youtubeAdjustment
  youtubeAdjustmentNet
  splitDeductionsTotal
  splitAdditionsTotal
`

export const assignUserToChannelFragment = `
  uuid
  userUuid
  siteUuid
`
export const assignGenresToChannelFragment = `
  uuid
  genreUuids
`

export const licenseFragment = `
  licenseUuid  
  limitUses  
  type 
`
