import { PERMISSIONS } from 'e2e-utils'
import { lazy } from 'react'
import { Navigate } from 'react-router-dom'
import { commonPublicAccountRoutes } from '../commonPublicAccountRoutes'
import { commonPublicRoutes } from '../commonPublicRoutes'

import { commonPrivateRoutes } from '../commonPrivateRoutes'
import { ISiteRouteList, RouteType } from '../types'

/**
 * LAZY LOAD ALL THE PAGES
 */
const Dashboard = lazy(() => import('../../pages/overview/DashboardPage'))
const MyAccountPage = lazy(() => import('../../pages/profile/MyAccountPage'))
const AssetListPage = lazy(() => import('../../pages/contentId/AssetListPage'))
const AudioUploadPage = lazy(() => import('../../pages/assets/audio/AudioUploadPage'))
const ManageAudios = lazy(() => import('../../pages/assets/audio/ManageAudiosPage'))
const MonthlyReportPage = lazy(() => import('../../pages/payments/MonthlyReportPage'))
const PaymentSelect = lazy(() => import('../../pages/payments/PaymentSelect/PaymentSelectPage'))
const TaxFormPage = lazy(() => import('../../pages/payments/TaxFormPage/TaxFormPage'))
const RegisterInvitation = lazy(() => import('../../pages/account/RegisterInvitation'))
const ClaimListClientPage = lazy(() => import('../../pages/contentId/claims/ClaimListClientPage'))
const DisputedClaimsClientPage = lazy(() => import('../../pages/contentId/claims/DisputedClaimsClientPage'))
const ManageChannelWhitelistingPage = lazy(
  () => import('../../pages/channelWhitelisting/ManageChannelWhitelistingPage')
)

const routes: ISiteRouteList = {
  // root route
  ROOT: {
    url: '/',
    exact: true,
    element: <Navigate to="/dashboard" />,
    routeType: RouteType.AUTH_PROTECTED,
    name: 'Dashboard',
    key: 'ROOT',
  },

  // public account pages

  REGISTER_INVITE: {
    key: 'REGISTER_INVITE',
    url: '/account/register-invite',
    name: 'Register',
    element: <RegisterInvitation />,
    routeType: RouteType.PUBLIC,
  },

  // auth protected routes
  DASHBOARD: {
    key: 'DASHBOARD',
    url: '/dashboard',
    name: 'Dashboard',
    element: <Dashboard />,
    routeType: RouteType.AUTH_PROTECTED,
  },
  MY_ACCOUNT: {
    key: 'MY_ACCOUNT',
    url: '/account/my-account',
    name: 'My Account',
    element: <MyAccountPage />,
    routeType: RouteType.AUTH_PROTECTED,
  },
  ASSETS_CONTENT_ID: {
    key: 'ASSETS_CONTENT_ID',
    url: '/content-id/assets',
    name: 'Assets',
    element: <AssetListPage />,
    permissionsOr: [PERMISSIONS.ASSET.LIST_SELF, PERMISSIONS.ASSET.MANAGE_SELF],
    routeType: RouteType.AUTH_PROTECTED,
  },
  CLAIMS_CONTENT_ID: {
    key: 'CLAIMS_CONTENT_ID',
    url: '/content-id/claims',
    name: 'Claims',
    element: <ClaimListClientPage />,
    permissionsOr: [PERMISSIONS.CLAIM.LIST_SELF, PERMISSIONS.CLAIM.RELEASE_SELF],
    routeType: RouteType.AUTH_PROTECTED,
  },
  DISPUTED_CLAIMS_CONTENT_ID: {
    url: '/content-id/disputed-claims',
    name: 'Disputed Claims',
    element: <DisputedClaimsClientPage />,
    permissionsAnd: [PERMISSIONS.CLAIM.LIST_SELF, PERMISSIONS.CLAIM.RELEASE_SELF],
    routeType: RouteType.AUTH_PROTECTED,
    key: 'DISPUTED_CLAIMS_CONTENT_ID',
  },
  AUDIO_UPLOAD: {
    key: 'AUDIO_UPLOAD',
    url: '/assets/audios/add',
    name: 'Audio Upload',
    element: <AudioUploadPage />,
    permissionsAnd: [PERMISSIONS.ASSET.MANAGE_SELF, PERMISSIONS.ASSET.LIST_SELF],
    routeType: RouteType.AUTH_PROTECTED,
  },
  AUDIO_LIST: {
    key: 'AUDIO_LIST',
    url: '/assets/audios/list',
    name: 'Manage Added Audios',
    element: <ManageAudios />,
    permissionsOr: [PERMISSIONS.ASSET.LIST_SELF],
    routeType: RouteType.AUTH_PROTECTED,
  },
  CHANNEL_WHITELISTING: {
    key: 'CHANNEL_WHITELISTING',
    url: '/channel-whitelisting',
    name: 'Channel Whitelisting',
    element: <ManageChannelWhitelistingPage />,
    permissionsOr: [PERMISSIONS.CHANNEL_WHITELISTING.LIST_SELF, PERMISSIONS.CHANNEL_WHITELISTING.MANAGE_SELF],
    routeType: RouteType.AUTH_PROTECTED,
  },
  MONTHLY_REPORT: {
    key: 'MONTHLY_REPORT',
    url: '/payments/monthly-report',
    name: 'Payment Reports',
    element: <MonthlyReportPage />,
    permissionsOr: [PERMISSIONS.EARNING.REPORT_EXPORT, PERMISSIONS.EARNING.LIST_SELF],
    routeType: RouteType.AUTH_PROTECTED,
  },
  PAYMENT_SELECT: {
    key: 'PAYMENT_SELECT',
    url: '/payment-select',
    name: 'Payment Select',
    element: <PaymentSelect />,
    routeType: RouteType.AUTH_PROTECTED,
  },
  TAX_FORMS: {
    key: 'TAX_FORMS',
    url: '/tax-forms',
    name: 'Payment Setup',
    element: <TaxFormPage />,
    routeType: RouteType.AUTH_PROTECTED,
  },
}

export const ELITE_ALLIANCE_MUSIC_ROUTES = {
  ...commonPublicRoutes,
  ...routes,
  ...commonPrivateRoutes(routes),
  ...commonPublicAccountRoutes,
}
