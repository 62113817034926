import { IGenericResponse } from '../../api.types'
import clientWrapper from '../../clientWrapper'
import { SERVICE_NAME } from '../../../constants'

import { IChartMetricUser } from '../../../models/chartMetric/ChartMetricUser.model'

export type ICreateChartMetricUserProps = {
  filters: {
    cmArtistId: number
    userUuid: string
    name: string
  }
}

export type ICreateChartMetricUserResponse = IGenericResponse<'createChartMetricUser', IChartMetricUser>

export async function createChartMetricUser(
  props: ICreateChartMetricUserProps
): Promise<ICreateChartMetricUserResponse> {
  const query = `
    mutation createcmuser($filters: CmUserCreateFiltersInput!) {
      createChartMetricUser(filters: $filters) {
        cmArtistId
        userUuid
        name
      }
    }
  `
  return clientWrapper(SERVICE_NAME.ASSETS, query, props) as Promise<ICreateChartMetricUserResponse>
}
