import { Instance, types } from 'mobx-state-tree'

import { PARTICIPANT_ROLES } from '../../constants'
import { Participant } from './Participant.model'

export const ParticipantWithRole = types.compose(
  Participant,
  types
    .model({
      role: types.enumeration(Object.values(PARTICIPANT_ROLES)),
    })
    .actions(self => ({
      setRole(role: PARTICIPANT_ROLES) {
        self.role = role
      },
    }))
)

export type IParticipantWithRole = Instance<typeof ParticipantWithRole>
