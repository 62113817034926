import { cast, flow, Instance, types } from 'mobx-state-tree'
import moment from 'moment'

import { getUsersOptimised, IGetUsersOptimisedResponse } from '../../api/users-api/users/getUsersOptimised'
import { SortDirection } from '../../constants'

import { Pagination } from '../general/Pagination.model'
import { IUserPaymentMethodBasic } from '../payment/UserPaymentMethodBasic.model'
import { IUser, User } from './User.model'
import { IUserBasic } from './UserBasic.model'

export const UserList = types
  .model({
    list: types.array(User),
  })
  .volatile(() => ({
    loading: true,
    pagination: Pagination.create({ totalItems: 1 }),
    multifieldFilter: '',
    methodFilter: '',
    sitesFilter: [] as string[],
    rolesFilter: [] as string[],
    labelsFilter: [] as string[],
    isInternalFilter: null as null | boolean,
    createdAtIntervalFilter: [null, null] as [Date | null, Date | null],
    incompletePaymentFilter: false,
    doNotPayFilter: false,
  }))
  .actions(self => ({
    addUser(user: IUser | IUserBasic) {
      self.list = cast([...self.list, user])
    },
    updateUser(updatedUser: IUser) {
      const idx = self.list.findIndex(user => user.uuid === updatedUser.uuid)

      if (idx >= 0) {
        self.list[idx] = updatedUser
      }
    },
  }))
  .actions(self => ({
    setUsers(userList: IUserBasic[] | IUser[]) {
      self.list = cast(userList)
    },
    setIsInternalFilter(val: boolean | null) {
      self.isInternalFilter = val
    },
    setUserPaymentMethods(paymentMethods: IUserPaymentMethodBasic[]) {
      const updatedUsers = self.list.map(user => {
        const paymentMethod = paymentMethods.find(pm => pm.userUuid === user.uuid)

        if (paymentMethod) {
          user.setUserPaymentMethod(paymentMethod)
        }

        return user
      })

      self.list = cast(updatedUsers)
    },
    getUserById(uuid: string | null | undefined) {
      if (uuid) {
        return self.list.find(user => user.uuid === uuid)
      }
      return null
    },
  }))
  .actions(self => ({
    load: flow(function* () {
      try {
        self.loading = true
        const resp: IGetUsersOptimisedResponse = yield getUsersOptimised()

        if (resp && resp.data.data) {
          self.setUsers(resp.data.data.usersOptimised)
        }
        self.pagination.setTotalItems(self.list.length)

        self.loading = false
      } catch (err) {
        self.loading = false
      }
    }),
  }))
  .views(self => ({
    get getUsers() {
      return self.list.slice().sort((a, b) => a.firstLastName.localeCompare(b.firstLastName))
    },
    get filtered() {
      const multifieldFilter = (item: IUser) => {
        if (!self.multifieldFilter) return true

        // const channels = item.channels
        //   .map((channel: IChannelBasic) => `${channel.uuid} ${channel.name}`)
        //   .reduce((curr: string, aggr: string) => curr + aggr, '')
        //   .toLowerCase()

        return (
          item.uuid.toLowerCase().includes(self.multifieldFilter) ||
          item.firstLastName.toLowerCase().includes(self.multifieldFilter) ||
          (item.displayName ? item.displayName.toLowerCase().includes(self.multifieldFilter) : false) ||
          (item.email ? item.email.toLowerCase().includes(self.multifieldFilter) : false) // ||
          // (item.channels.length > 0 ? channels.includes(self.multifieldFilter) : false)
        )
      }

      const methodFilter = (item: IUser) => {
        if (!self.methodFilter) return true
        if (!item.userPaymentMethod.uuid) return false
        return item.userPaymentMethod.paymentMethod?.uuid === self.methodFilter
      }

      const incompletePaymentMethodFilter = (item: IUser) => {
        if (!self.incompletePaymentFilter) return true
        return item.userPaymentMethod.status !== 'COMPLETED'
      }

      const doNotPayFilter = (item: IUser) => {
        if (self.doNotPayFilter === false) return true

        return item.doNotPay === self.doNotPayFilter
      }

      const sitesFilter = (item: IUser) => {
        if (self.sitesFilter.length === 0) return true

        return (
          self.sitesFilter.filter(
            siteFilter => item.userSites.map(site => site.siteUuid).filter(site => site === siteFilter).length > 0
          ).length > 0
        )
      }

      const rolesFilter = (item: IUser) => {
        if (self.rolesFilter.length === 0) return true

        return (
          self.rolesFilter.filter(
            roleFilter => item.roles.map(role => role.uuid).filter(role => role === roleFilter).length > 0
          ).length > 0
        )
      }

      const labelsFilter = (item: IUser) => {
        if (self.labelsFilter.length === 0) return true

        return (
          self.labelsFilter.filter(
            labelFilter => item.labels.map(label => label.uuid).filter(label => label === labelFilter).length > 0
          ).length > 0
        )
      }

      const createdAtIntervalFilter = (item: IUser) => {
        if (!self.createdAtIntervalFilter[0] || !self.createdAtIntervalFilter[1]) return true

        return moment(item.createdAt).isBetween(
          moment(self.createdAtIntervalFilter[0]),
          moment(self.createdAtIntervalFilter[1])
        )
      }

      const isInternalFilter = (item: IUser) => {
        if (self.isInternalFilter === null) return true

        return item.hasInternalRole === self.isInternalFilter
      }

      const filteredUsers = self.list
        .filter(multifieldFilter)
        .filter(methodFilter)
        .filter(sitesFilter)
        .filter(rolesFilter)
        .filter(labelsFilter)
        .filter(incompletePaymentMethodFilter)
        .filter(doNotPayFilter)
        .filter(createdAtIntervalFilter)
        .filter(isInternalFilter)

      // sort
      if (self.pagination.sort) {
        filteredUsers.sort((a, b) => {
          if (self.pagination.sort === 'firstLastName') {
            if (a.firstLastName.toLowerCase() < b.firstLastName.toLowerCase()) {
              return self.pagination.sortDirection === SortDirection.DESC ? -1 : 1
            }
            if (a.firstLastName.toLowerCase() > b.firstLastName.toLowerCase()) {
              return self.pagination.sortDirection === SortDirection.DESC ? 1 : -1
            }
          }

          if (self.pagination.sort === 'displayName') {
            if ((a.displayName?.toLowerCase() || '') < (b.displayName?.toLowerCase() || '')) {
              return self.pagination.sortDirection === SortDirection.DESC ? -1 : 1
            }
            if ((a.displayName?.toLowerCase() || '') > (b.displayName?.toLowerCase() || '')) {
              return self.pagination.sortDirection === SortDirection.DESC ? 1 : -1
            }
          }

          return 0
        })
      }

      return filteredUsers
    },
  }))
  .views(self => ({
    get filteredCount() {
      return self.filtered.length
    },
    get pagedFiltered() {
      return self.filtered.slice(
        (self.pagination.page - 1) * self.pagination.perPage,
        self.pagination.page * self.pagination.perPage
      )
    },
  }))
  .actions(self => ({
    setMultifieldFilter(text: string) {
      self.multifieldFilter = text.toLowerCase()
      self.pagination.setTotalItems(self.filteredCount)
      self.pagination.setPage(1)
    },
    setSitesFilter(sites: string[]) {
      self.sitesFilter = sites
      self.pagination.setTotalItems(self.filteredCount)
      self.pagination.setPage(1)
    },
    setIncompleteMethodFilter(val: boolean) {
      self.incompletePaymentFilter = val
      self.pagination.setTotalItems(self.filteredCount)
      self.pagination.setPage(1)
    },
    setDoNotPayFilter(val: boolean) {
      self.doNotPayFilter = val
      self.pagination.setTotalItems(self.filteredCount)
      self.pagination.setPage(1)
    },
    setRolesFilter(roles: string[]) {
      self.rolesFilter = roles
      self.pagination.setTotalItems(self.filteredCount)
      self.pagination.setPage(1)
    },
    setLabelsFilter(labels: string[]) {
      self.labelsFilter = labels
      self.pagination.setTotalItems(self.filteredCount)
      self.pagination.setPage(1)
    },
    setMethodFilter(method: string) {
      self.methodFilter = method
      self.pagination.setTotalItems(self.filteredCount)
      self.pagination.setPage(1)
    },
    setCreatedAtIntervalFilter(interval: [Date | null, Date | null]) {
      self.createdAtIntervalFilter = interval
      self.pagination.setTotalItems(self.filteredCount)
      self.pagination.setPage(1)
    },
  }))

export type IUserList = Instance<typeof UserList>
