import { Instance, types } from 'mobx-state-tree'

export const ChartMetric = types.model({
  uuid: types.maybeNull(types.string),
  createdAt: types.maybeNull(types.string),
  updatedAt: types.maybeNull(types.string),
  updatedBy: types.maybeNull(types.string),
  deletedAt: types.maybeNull(types.string),
  deletedBy: types.maybeNull(types.string),
  playlist: types.maybeNull(
    types.model({
      name: types.maybeNull(types.string),
      playlist_id: types.maybeNull(types.string),
      followers: types.maybeNull(types.number),
      curator_name: types.maybeNull(types.string),
      platform: types.maybeNull(types.string),
      added_at: types.maybeNull(types.string),
      removed_at: types.maybeNull(types.string),
      peak_position: types.maybeNull(types.number),
      releaseUuid: types.maybeNull(types.string),
    })
  ),
  track: types.maybeNull(
    types.model({
      name: types.maybeNull(types.string),
      isrc: types.maybeNull(types.string),
      trackUuid: types.maybeNull(types.string),
      cm_track: types.maybeNull(types.number),
      cm_artist: types.maybeNull(types.array(types.number)),
      artist_names: types.maybeNull(types.array(types.string)),
    })
  ),
  users: types.maybeNull(
    types.array(
      types.model({
        userUuid: types.maybeNull(types.string),
        isOwner: types.maybeNull(types.boolean),
      })
    )
  ),
  url: types.maybeNull(types.string),
})

export type IChartMetric = Instance<typeof ChartMetric>
