import { IGenericResponse } from '../../api.types'
import clientWrapper from '../../clientWrapper'

import { CM_PLAYLIST_PLATFORM_FILTER, SERVICE_NAME } from '../../../constants'
import { IPaginationParams } from '../../../types'
import { IChartMetric } from '../../../models/chartMetric/ChartMetric.model'

export type IGetChartMetricPlaylistsProps = {
  filters: {
    search?: string
    userUuids?: string[]
    playlist_id?: string
    trackUuid?: string
    platform?: CM_PLAYLIST_PLATFORM_FILTER
    releaseUuid?: string
    startDateAdded?: string
    endDateAdded?: string
  }
  pagination?: IPaginationParams
}

export type IGetChartMetricPlaylistsResponse = IGenericResponse<
  'chartMetricPlaylists',
  { playlists: IChartMetric[]; total: number }
>

export async function getChartMetricPlaylists(
  props: IGetChartMetricPlaylistsProps
): Promise<IGetChartMetricPlaylistsResponse> {
  const query = `
     query ChartMetricsPlaylistsQuery($filters: CmPlaylistFilterInput!, $pagination: PaginationInput) {
    chartMetricPlaylists(filters: $filters, pagination: $pagination) {
      playlists {
        uuid
        createdAt
        updatedAt
        updatedBy
        deletedAt
        deletedBy
        playlist {
          name
          playlist_id
          followers
          curator_name
          platform
          added_at
          removed_at
          peak_position
        }
        track {
          name
          isrc
          trackUuid
          cm_track
          cm_artist
          artist_names
        }
        users {
          userUuid
          isOwner
        }
        url
      }
      total
    }
}
  `
  return clientWrapper(SERVICE_NAME.ASSETS, query, props) as Promise<IGetChartMetricPlaylistsResponse>
}
