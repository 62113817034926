import { IGenericResponse } from '../../api.types'
import clientWrapper from '../../clientWrapper'
import { SERVICE_NAME } from '../../../constants'

import { IPaginationParams } from '../../../types'
import { IChartMetric } from '../../../models/chartMetric/ChartMetric.model'

export type IGetChartMetricUsersProps = {
  filters: {
    cmArtistIds?: string[]
    userUuids?: string[]
  }
  pagination?: IPaginationParams
}

export type IGetChartMetricUsersResponse = IGenericResponse<
  'chartMetricUsers',
  { users: IChartMetric[]; total: number }
>

export async function getChartMetricUsers(props: IGetChartMetricUsersProps): Promise<IGetChartMetricUsersResponse> {
  const query = `
    query cmUsers($filters: CmUsersFilterInput!) {
      chartMetricUsers(filters: $filters) {
        users {
          cmArtistId
          userUuid
          name
        }
        total
      }
    }  
  `
  return clientWrapper(SERVICE_NAME.ASSETS, query, props) as Promise<IGetChartMetricUsersResponse>
}
