import { APPROVAL_STATES, PLATFORM_STATES } from '@epic-front/common/src/constants'
import { capitalizeFirstLetter } from '@epic-front/common/src/helpers'

interface IReleaseStatusBadge {
  assetStatus: APPROVAL_STATES | PLATFORM_STATES | string | null
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
}

const NO_STATE = '-/-'

const statusBackground: { [key: string]: string } = {
  [APPROVAL_STATES.DRAFT]: 'status-neutral',
  [APPROVAL_STATES.IMPORTED]: 'status-info',
  [APPROVAL_STATES.REJECTED]: 'status-danger',
  [APPROVAL_STATES.APPROVED]: 'bg-success border-success',
  [APPROVAL_STATES.PENDING_APPROVAL]: 'status-warning',
  [PLATFORM_STATES.PENDING]: 'status-warning',
  [PLATFORM_STATES.QUEUED_FOR_DELIVERY]: 'status-info',
  [PLATFORM_STATES.DELIVERY_STARTED]: 'status-info',
  [PLATFORM_STATES.DELIVERY_FINISHED]: 'bg-success border-success',
  [PLATFORM_STATES.DELIVERY_ERROR]: 'status-danger',
  [PLATFORM_STATES.LIVE]: 'bg-success border-success',
  [PLATFORM_STATES.UPCOMING]: 'bg-success border-success',
  [PLATFORM_STATES.TAKEN_DOWN]: 'status-danger',
  [APPROVAL_STATES.EMPTY]: 'status-neutral',
  [NO_STATE]: 'status-neutral',
}

const statusIconType: { [key: string]: string } = {
  [APPROVAL_STATES.DRAFT]: 'uil-pen',
  [APPROVAL_STATES.IMPORTED]: 'uil-arrow-down',
  [APPROVAL_STATES.REJECTED]: 'uil-exclamation-circle',
  [APPROVAL_STATES.APPROVED]: 'uil-check',
  [APPROVAL_STATES.PENDING_APPROVAL]: 'uil-sync',
  [PLATFORM_STATES.PENDING]: 'uil-sync',
  [PLATFORM_STATES.QUEUED_FOR_DELIVERY]: 'uil-clock',
  [PLATFORM_STATES.DELIVERY_STARTED]: 'uil-play',
  [PLATFORM_STATES.DELIVERY_FINISHED]: 'uil-check',
  [PLATFORM_STATES.DELIVERY_ERROR]: 'uil-exclamation-octagon',
  [PLATFORM_STATES.LIVE]: 'uil-check-circle',
  [PLATFORM_STATES.UPCOMING]: 'uil-angle-double-right',
  [PLATFORM_STATES.TAKEN_DOWN]: 'uil-exclamation-triangle',
  [APPROVAL_STATES.EMPTY]: 'uil-exclamation-circle',
  [NO_STATE]: 'uil-exclamation-circle',
}

const ReleaseStatusBadge: React.FC<IReleaseStatusBadge> = ({ assetStatus, size = 'xs' }): JSX.Element => {
  const defaultState = NO_STATE

  const sizeClass = size ? `badge-${size}` : ''

  return (
    <div className={`badge badge-status ${statusBackground[assetStatus || defaultState]} ${sizeClass}`}>
      <i className={`uil ${statusIconType[assetStatus || defaultState]}`}> </i>
      <span>{capitalizeFirstLetter((assetStatus || defaultState).replaceAll('_', ' '))}</span>
    </div>
  )
}

export default ReleaseStatusBadge
