import { IGenericResponse } from '../../api.types'
import clientWrapper from '../../clientWrapper'
import { SERVICE_NAME } from '../../../constants'

export type IDeleteChartMetricUsersProps = {
  uuids: string[]
}

export type IDeleteChartMetricUsersResponse = IGenericResponse<'deleteChartMetricUsers', boolean>

export async function deleteChartMetricUsers({
  uuids,
}: IDeleteChartMetricUsersProps): Promise<IDeleteChartMetricUsersResponse> {
  const query = `
   mutation DeleteChartMetricUsers($uuids: [String!]!) {
    deleteChartMetricUsers(uuids: $uuids)
  }
  `
  return clientWrapper(SERVICE_NAME.ASSETS, query, { uuids }) as Promise<IDeleteChartMetricUsersResponse>
}
